//@ts-ignore
import Category from './Category.ts';
//@ts-ignore
import Service from './Service.ts';
//@ts-ignore
import Attribute from './Attribute.ts';
import axios from 'axios';

import axiosECommerce from 'axios';
//@ts-ignore
import ECommerceConf from '../conf/ECommerceConf.ts';
//@ts-ignore
import FilterObjects from './FilterObjects.ts';
//@ts-ignore
import BusinessServiceOrder from '../types/BusinessServiceOrder.ts';

export default class ECommerce {

    constructor() {

    }

    async validateOnlinePayments(dates) {
        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                            validateOnlinePayments(queryValidatePayments: {
                            start_date: "${dates.start_date}",
                            end_date: "${dates.end_date}"
                            }) {
                                id_transaction
                                fk_ecommerce_orders
                                response
                                status
                                cus
                                start_date
                                end_date
                            }
                        }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.validateOnlinePayments);

            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTERNER LAS ESTADISTICAS ', error);


            });

        });
    }

    async startECommerce() {

        const ecom = await this.getCategories({
            get_all: true,
            get_active: false,
            has_services: false,
            has_services_items: false,
            id_user: 0
        });

        console.log(ecom);
    }

    async filterCategories(arrayObjects, filterKey) {

        return new FilterObjects().filterArrayObject(arrayObjects, 'tags', filterKey);

    }

    async filterUsers(arrayObjects, filterKey) {

        return new FilterObjects().filterArrayObject(arrayObjects, 'names', filterKey);

    }

    async filterOrders(arrayObjects, filterKey) {

        let arrayFiltered = [];

        if (isNaN(Number.parseInt(filterKey))) {

            arrayFiltered = new FilterObjects().filterArrayObject(arrayObjects, 'customer', filterKey);

        } else {

            if (filterKey.lastIndexOf('-') !== -1) {

                arrayFiltered = new FilterObjects().filterArrayObject(arrayObjects, 'timeline', filterKey);

            } else {

                arrayFiltered = new FilterObjects().filterArrayObjectSingle(arrayObjects, 'id_order', filterKey);

            }
        }

        return arrayFiltered;

    }

    async getOrderImages(id_order) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/uploadFile', {
                id_order: id_order
            }).then((images) => {
                resolve(images);
            })

        });

    }

    async paginateArrayObject(arrayObjects) {

        return new FilterObjects().paginateArrayObject(arrayObjects, 4, 6);
    }

    async getECommerceStats(queryStats) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getECommerceStats(queryECommerceStats: {
                      type: 0,
                      start_date: "",
                      end_date: ""
                    }) {
                      columns
                      values
                    }
                  }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getECommerceStats);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTERNER LAS ESTADISTICAS ', error);


            });

        });

    }



    async updateCycleStatus(queryUpdateCycle) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                        updateCycleStatus(queryUpdateCycle: ${JSON.stringify(queryUpdateCycle).replace(/"([^"]+)":/g, '$1:')}) {
                            id_ecommerce_cycles
                created_at
                process_at
                                
                
                      }
                }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.updateCycleStatus);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL ACTUALIZAR EL CICLO ', error);


            });

        });

    }

    async updateBillingAreas(queryUpdateBillingAreas) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    updateBillingAreas(billingAreas: ${JSON.stringify(queryUpdateBillingAreas).replace(/"([^"]+)":/g, '$1:')}) {
                        id_billing_areas
                        fk_city_ecommerce_bill_areas
                        lat
                        lng
                        value
                            
            
                  }
            }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.updateBillingAreas);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL ACTUALIZAR LAS AREAS DE PRECIOS ', error);


            });

        });

    }

    async createBillingAreas(queryUpdateBillingAreas) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    createBillingAreas(billingAreas: ${JSON.stringify(queryUpdateBillingAreas).replace(/"([^"]+)":/g, '$1:')}) {
                        id_billing_areas
                        fk_city_ecommerce_bill_areas
                        lat
                        lng
                        value
                            
            
                  }
            }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.createBillingAreas);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL CREAR UNA NUEVA AREA DE PRECIOS ', error);


            });

        });

    }

    async getECommerceBillingAreas(queryBillingAreas) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getECommerceBillingAreas(queryBillingAreas: ${JSON.stringify(queryBillingAreas).replace(/"([^"]+)":/g, '$1:')}) {
                        id_billing_areas
                        fk_city_ecommerce_bill_areas
                        lat
                        lng
                        value
                            
            
                  }
            }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getECommerceBillingAreas);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER los items del servicio ', error);


            });

        });

    }


    async getCategoryServicesItems(id_service) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                getCategoryServicesItems(id_service: ${id_service}) {
                                        id_service
                            id_service_item
                            name
                            ean
                            refId
                            image
                            type
                            price
                            weight
                            quantity
                            code
                            
            
                  }
            }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getCategoryServicesItems);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER los items del servicio ', error);


            });

        });

    }

    getBanks() {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `
                        query {
  
                            getBanks {
                                id_bank
                                name
                            }
                          }`

            }).then((response) => {
                resolve(response.data.data.getBanks);
            })

        });

    }



    async addServiceToProvider(options) {
        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                addServiceToProvider(queryAddService: ${JSON.stringify(options).replace(/"([^"]+)":/g, '$1:')})  {
                    id_provider_service
                    id_service

                }

                }`
            }).then((addedServiceToProvider) => {

                resolve(addedServiceToProvider.data.data.addServiceToProvider);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }
    async createPromocional(newPromocional) {
        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));
console.log(newPromocional);
            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                createPromocional(promocionalInput: ${JSON.stringify(newPromocional).replace(/"([^"]+)":/g, '$1:')})  {
                    id_promocional
                created_at
                is_active
                is_available
                code
                name
                description
                img
                title
                avatar
                userFullName
                blogPosted
                tags {
                    tags
                }
                excerpt
                comment
                services {
                    id_service
                id_service_item
                label
                is_new
                id_provider
                }

            }

                }`
            }).then((createdPromocional) => {

                resolve(createdPromocional.data.data.createPromocional);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async updatePromocional(newPromocional) {

        console.log('sisa', newPromocional);
        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));
            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                updatePromocional(promocionalInput: ${JSON.stringify(newPromocional).replace(/"([^"]+)":/g, '$1:')})  {
                    id_promocional
                created_at
                is_active
                is_available
                code
                name
                description
                img
                title
                avatar
                userFullName
                blogPosted
                tags {
                    tags
                }
                excerpt
                comment
                services {
                    id_service
                id_service_item
                label
                is_new
                id_provider
                }

            }

                }`
            }).then((createdPromocional) => {

                resolve(createdPromocional.data.data.updatePromocional);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }






    async getECommercePayOrdersReport(queryPayOrders) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getECommercePayOrdersReport(queryApprovePayOrders: ${JSON.stringify(queryPayOrders).replace(/"([^"]+)":/g, '$1:')}) {
                  
          affiliate_amount_paid
                          afiliate_amount
                          approved_at
                          complete
                          created_at
                          debit_at
                          dispersion_at
                          ecommerce_amount
                          ecommerce_amount_paid
                          fk_ecommerce_providers_ecpaorder
                          id_pay_orders
                          payorder_number
                          posted
              bills {
                id_ecommerce_billing
                fk_ecommerce_order_ec_billing
                bill {
                                    items {
                                        id_service
                                      name
                                      items {
                                        id_service
                                        id_service_item
                                        quantity
                                        name
                                        price
                                        ean
                                        refId
                                        
                                      }
                                      
                                      partners {
                                         name
                                              lat
                                              lng
                                              plan {
                                                distance
                                                time
                                                planning {
                                                                  query_date
                                                          collect_date
                                                          process_date
                                                          receive_date
                                                          shipping_date
                                                          postal_address
                                                          prepareCollect_date
                                                          prepareShipping_date
                                                  
                                                }
                                              }
                                              id_provider
                                      }
                                    }
                                    id_bill,
                                    accounting {
                                      payOrders {
                                         taxes
                                              amount
                                              method
                                              status
                                              debit_at
                                              created_at
                                              id_provider
                                              service_qty
                                              id_pay_order
                                              service_items_qty
                                      }
                                      invoices {
                                        taxes
                                              posted
                                              completed
                                              invoice_id
                                              invoice_number
                                              affiliate_amount
                                              ecommerce_amount
                                              affiliate_amount_paid
                                              ecommerce_amount_paid
                                              id_provider
                                      }
                                    }
                                    payment {
                               cus
                                      ref
                                      date
                                      amount
                                      receiver
                                      payment_method
                                      financial_entity
                                      
                                    },
                                    customer {
                                                      avatar
                                              company
                                              created_at
                                              email
                                              email_verified_at
                                              fk_user_customization
                                              id_scanclick_users
                                              names
                                              password
                                              remember_token
                                              surnames
                                              updated_at
                                              gov_id
                                              type
                                              full_name
                                              location {
                                                 id_city
                                              city_name
                                              id_country
                                              country_name
                                              lat
                                              lng
                                              name
                                              description
                                              postal_address
                                              }
                                              id_customer
                                    }
                                    
                                    
                                  }
              }
                
              
            }
          }
          `
            }).then((response) => {

                resolve(response.data.data.getECommercePayOrdersReport);

            }).catch((error) => {

                resolve([]);

            });

        });




    }

    async getECommerceCustomer(queryOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceMicroService', {
                query: `query {
                            getECommerceCustomer(queryECommerceCustomer: ${JSON.stringify(queryOptions).replace(/"([^"]+)":/g, '$1:')}) {
                                avatar
                                company
                                created_at
                                email
                                email_verified_at
                                fk_user_customization
                                id_scanclick_users
                                names
                                password
                                remember_token
                                surnames
                                updated_at
                                gov_id
                                type
                                full_name
                                id_customer
                                location {
                                  id_city
                                  id_country
                                  city_name
                                  country_name
                                  lat
                                  lng
                                  name
                                  description
                                  postal_address
                                }                       
                            }
                        }`

            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getECommerceCustomer);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER los clientes del ecommerce ', error);

            });

        });

    }

    async createNewCycle(queryNewCycle) {

        console.log(queryNewCycle);

        return new Promise((resolve, reject) => {
            //
            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    createNewCycle(queryECommerceCycle: ${JSON.stringify(queryNewCycle, null, 0).replace(/"([^"]+)":/g, '$1:')})
                }`
            }).then((result) => {

                resolve(result.data.data.createNewCycle);

            }).catch((error) => {
                resolve(-1);
            })

        });

    }

    async createPayOrder(queryCreatePayOrder) {

        return new Promise((resolve, reject) => {
            //
            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    createPayOrder(queryCreatePayOrder: ${JSON.stringify(queryCreatePayOrder).replace(/"([^"]+)":/g, '$1:')}) {
name
    id_provider
    bills {
                id_ecommerce_billing
                fk_ecommerce_order_ec_billing
                bill {
                                    items {
                                        id_service
                                      name
                                      items {
                                        id_service
                                        id_service_item
                                        quantity
                                        name
                                        price
                                        ean
                                        refId
                                        
                                      }
                                      
                                      partners {
                                         name
                                              lat
                                              lng
                                              plan {
                                                distance
                                                time
                                                planning {
                                                                  query_date
                                                          collect_date
                                                          process_date
                                                          receive_date
                                                          shipping_date
                                                          postal_address
                                                          prepareCollect_date
                                                          prepareShipping_date
                                                  
                                                }
                                              }
                                              id_provider
                                      }
                                    }
                                    id_bill,
                                    accounting {
                                      payOrders {
                                         taxes
                                              amount
                                              method
                                              status
                                              debit_at
                                              created_at
                                              id_provider
                                              service_qty
                                              id_pay_order
                                              service_items_qty
                                      }
                                      invoices {
                                        taxes
                                              posted
                                              completed
                                              invoice_id
                                              invoice_number
                                              affiliate_amount
                                              ecommerce_amount
                                              affiliate_amount_paid
                                              ecommerce_amount_paid
                                              id_provider
                                      }
                                    }
                                    payment {
                               cus
                                      ref
                                      date
                                      amount
                                      receiver
                                      payment_method
                                      financial_entity
                                      
                                    },
                                    customer {
                                                      avatar
                                              company
                                              created_at
                                              email
                                              email_verified_at
                                              fk_user_customization
                                              id_scanclick_users
                                              names
                                              password
                                              remember_token
                                              surnames
                                              updated_at
                                              gov_id
                                              type
                                              full_name
                                              location {
                                                 id_city
                                              city_name
                                              id_country
                                              country_name
                                              lat
                                              lng
                                              name
                                              description
                                              postal_address
                                              }
                                              id_customer
                                    }
                                    
                                    
                                  }
              }
                
              
            }
          }`
            }).then((response) => {

                resolve(response.data.data.createPayOrder);

            }).catch((error) => {

                resolve([]);

            });

        });




    }

    async getECommerceCycleInvoices(queryDispersion) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
  
                    getECommerceCycleInvoices(queryDispersion: ${JSON.stringify(queryDispersion).replace(/"([^"]+)":/g, '$1:')}) {
                        id_ecommerce_invoices
                        fk_ecommerce_bill_ec_invoices
                        fk_ecommerce_cycles_ec_invoic
                        created_at
                        debited_at
                        invoice_number
                        affiliate_amount
                        ecommerce_amount
                        ecommerce_amount_paid
                        affiliate_amount_paid
                        complete
                        posted
                        taxes
                        id_ecommerce_providers
                        fk_ecommerce_users_ecom_providers
                        name
                        gov_id
                        bank_number
                        bank
                        bills
                      
                    }
                    
                  
                    
                  }
                  `
            }).then((response) => {

                resolve(response.data.data.getECommerceCycleInvoices);

            }).catch((error) => {

                resolve([]);

            });

        });




    }

    async getECommerceCycleDispersion(queryDispersion) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
  
                    getECommerceCycleDispersion(queryDispersion: ${JSON.stringify(queryDispersion).replace(/"([^"]+)":/g, '$1:')}) {
                  id_pay_orders
                  fk_ecommerce_providers_ecpaorder
                  created_at
                  debit_at
                  approved_at
                  payorder_number
                  afiliate_amount
                  ecommerce_amount
                  ecommerce_amount_paid
                  affiliate_amount_paid
                  complete
                  posted
                  dispersion_at
                  id_ecommerce_providers
                  fk_ecommerce_users_ecom_providers
                  name
                  gov_id
                  bank_number
                  bank
                  bills
                      
                    }
                    
                  
                    
                  }
                  `
            }).then((response) => {

                resolve(response.data.data.getECommerceCycleDispersion);

            }).catch((error) => {

                resolve([]);

            });

        });




    }

    async getECommerceCycles(queryCycles) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
  
                    getECommerceCycles(queryCycles: {
                      type: 0
                    }) {
                      id_ecommerce_cycles
                      created_at
                      process_at
                      observation
                      id_owner
                      cycle {
                        status
                        total_payorder
                        total_invoice
                        partner_name
                        id_provider
                        cycle {
                          id_provider
                          partner {
                            plan {
                              planning {
                                query_date
                              }
                            }
                          }
                          customer {
                  avatar
                                  company
                                  created_at
                                  email
                                  email_verified_at
                                  fk_user_customization
                                  id_scanclick_users
                                  names
                                  password
                                  remember_token
                                  surnames
                                  updated_at
                                  gov_id
                                  type
                                  full_name
                                  location {
                                    id_city
                                  city_name
                                  id_country
                                  country_name
                                  lat
                                  lng
                                  name
                                  description
                                  postal_address
                                  }
                                  id_customer
                      
                          }
                          items {
                             id_service
                                  name
                                  partners {
                                    id_provider
                                  name
                                  lat
                                  lng
                                    plan {
                                      distance
                                  time
                                  planning {
                                       postal_address
                                  query_date
                                  prepareCollect_date
                                  collect_date
                                  receive_date
                                  process_date
                                  prepareShipping_date
                                  shipping_date
                                    }
                                    }
                                  }
                                  img
                                  items {
                                    id_service
                                  id_service_item
                                  name
                                  ean
                                  refId
                                  image
                                  type
                                  price
                                  weight
                                  quantity
                                  code
                                  }
                          }
                          payOrders {
                            payOrders {
                             taxes
                                  amount
                                  method
                                  status
                                  debit_at
                                  created_at
                                  id_provider
                                  service_qty
                                  id_pay_order
                                  service_items_qty
                            }
                          }
                          invoices {
                            id_order
                            type
                            invoice {
                              posted
                                  completed
                                  invoice_id
                                  invoice_number
                                  affiliate_amount
                                  ecommerce_amount
                                  affiliate_amount_paid
                                  ecommerce_amount_paid
                                  id_provider
                            }
                          }
                        }
                      }
                      
                    }
                    
                  
                    
                  }
                  `
            }).then((response) => {

                resolve(response.data.data.getECommerceCycles);

            }).catch((error) => {

                resolve([]);

            });

        });




    }

    async getECommerceInvoices(queryECommerceInvoice) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getECommerceInvoices(queryInvoices: ${JSON.stringify(queryECommerceInvoice).replace(/"([^"]+)":/g, '$1:')}) {
                       created_at
                                    debited_at
                                    cus
                                    fk_provider
                                    invoice_number
                                    affiliate_amount
                                    affiliate_amount_paid 
                                    ecommerce_amount 
                                    ecommerce_amount_paid
                                    complete
                                    posted
                                    taxes
                                    id_ecommerce_invoices
                                    id_order
                    }
                                    }`

            }).then((response) => {
                console.log('llegue');
                resolve(response.data.data.getECommerceInvoices);

            }).catch((error) => {

                resolve([]);

            });

        });


    }

    async getECommerceReport(queryOptions) {

        return new Promise((resolve, reject) => {
            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {

                query: `query {
                    getECommerceReport(queryECommerceBill: ${JSON.stringify(queryOptions).replace(/"([^"]+)":/g, '$1:')}) {
                    
                    id_provider
                        name
                   bills {
                    fk_ecommerce_order_ec_billing
                    bill {
                        
                        items {
                            id_service
                          name
                          items {
                            id_service
                            id_service_item
                            quantity
                            name
                            price
                            ean
                            refId
                            
                          }
                          
                          partners {
                             name
                                  lat
                                  lng
                                  plan {
                                    distance
                                    time
                                    planning {
                                                      query_date
                                              collect_date
                                              process_date
                                              receive_date
                                              shipping_date
                                              postal_address
                                              prepareCollect_date
                                              prepareShipping_date
                                      
                                    }
                                  }
                                  id_provider
                          }
                        }
                        id_bill,
                        accounting {
                          payOrders {
                             taxes
                                  amount
                                  method
                                  status
                                  debit_at
                                  created_at
                                  id_provider
                                  service_qty
                                  id_pay_order
                                  service_items_qty
                          }
                          invoices {
                            taxes
                                  posted
                                  completed
                                  invoice_id
                                  invoice_number
                                  affiliate_amount
                                  ecommerce_amount
                                  affiliate_amount_paid
                                  ecommerce_amount_paid
                                  id_provider
                          }
                        }
                        payment {
                   cus
                          ref
                          date
                          amount
                          receiver
                          payment_method
                          financial_entity
                          
                        },
                        customer {
                                          avatar
                                  company
                                  created_at
                                  email
                                  email_verified_at
                                  fk_user_customization
                                  id_scanclick_users
                                  names
                                  password
                                  remember_token
                                  surnames
                                  updated_at
                                  gov_id
                                  type
                                  full_name
                                  location {
                                     id_city
                                  city_name
                                  id_country
                                  country_name
                                  lat
                                  lng
                                  name
                                  description
                                  postal_address
                                  }
                                  id_customer
                        }
                        
                        
                      }
                   }
                    
                  }
                  
                }`

            }).then((categoriesServicesList) => {
                console.log('0saliendo', categoriesServicesList.data.data.getECommerceReport)
                resolve(categoriesServicesList.data.data.getECommerceReport);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER el reporte del ecommerce ', error);

            });

        });

    }
    async getProviderOrders(queryOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getProviderOrders(queryOrders: ${JSON.stringify(queryOptions).replace(/"([^"]+)":/g, '$1:')}) {
                        id_order
                        timeline {
                          created_at
                          request_date
                          deadline_date
                          order_date
                          accept_date
                          assigment_date
                          payment_date
                          completed_date
                          cancel_date
                          refund_date
                        }
                                order {
                                
                                    images
                                id_order
                                                    customer { 
                                                        avatar
                                                        company
                                                        created_at
                                                        email
                                                        email_verified_at
                                                        fk_user_customization
                                                        id_scanclick_users
                                                        names
                                                        password
                                                        remember_token
                                                        surnames
                                                        updated_at
                                                        gov_id
                                                        type
                                                        full_name
                                                        location { 
                                                            id_city
                                                            city_name
                                                            id_country
                                                            country_name
                                                            lat
                                                            lng
                                                            name
                                                            description
                                                            postal_address
                                                        }
                                                        id_customer
                                                    }
                                                    partners { 
                                                        id_provider
                                                        lat
                                                        lng
                                                        name
                                                        plan {
                                                            distance
                                                            time
                                                            planning {
                                                                postal_address
                                                                query_date
                                                                prepareCollect_date
                                                                collect_date
                                                                receive_date
                                                                process_date
                                                                prepareShipping_date
                                                                shipping_date
                                                            }
                                                        }

                                                    }
                                                    type
                                                    carrierServiceType
                                                    description
                                                    comments
                                                    paymentMethod
                                                    timezone
                                                    logistic {
                                                        logistic {
                                                            name
                                                            id_service
                                                            partners {
                                                                id_provider
                                                                name
                                                                lat
                                                                lng
                                                                plan {
                                                                    planning {
                                                                        postal_address
                                                                        query_date
                                                                        prepareCollect_date
                                                                        collect_date
                                                                        receive_date
                                                                        process_date
                                                                        prepareShipping_date
                                                                        shipping_date

                                                                    }
                                                                }
                                                            }
                                                            items {
                                                                id_service_item
                                                            }
                                                            img

                                                        }
                                                        collect {
                                                            start_date
                                                            transit_date
                                                            delivery_date
                                                            devolution_date
                                                            items {
                                                                id_service
                                                                id_service_item
                                                                name
                                                                ean
                                                                refId
                                                                image
                                                                type
                                                                price
                                                                weight
                                                                quantity
                                                                code
                                                            }
                                                            total_qty
                                                            total_weight
                                                            partners {
                                                                id_provider
                                                                lat
                                                                lng
                                                                name
                                                            } 
                                                        }
                                                        shipping {
                                                            start_date
                                                            transit_date
                                                            delivery_date
                                                            devolution_date
                                                            items {
                                                                id_service
                                                                name
                                                                ean
                                                                refId
                                                                image
                                                                type
                                                                price
                                                                weight
                                                                quantity
                                                                code
                                                            }
                                                            total_qty
                                                            total_weight
                                                        }
                                                    }
                                                    timeline {
                                                        created_at
                                                        request_date
                                                        deadline_date
                                                        order_date
                                                        accept_date
                                                        assigment_date
                                                        payment_date
                                                        completed_date
                                                        cancel_date
                                                        refund_date
                                                    }
                                                    billing {
                                                        id_bill
                                                    },
                                                    
                                }
                                
                                
                                
                              }
                                        }`

            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getProviderOrders);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER LAS ORDENES del PROVEEDOR ', error);

            });

        });

    }



    async getECommerceProvider(queryOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceMicroService', {
                query: `query {
                    getECommerceProvider(queryECommerceProvider: ${JSON.stringify(queryOptions).replace(/"([^"]+)":/g, '$1:')}) {
                        avatar
                        company
                        created_at
                        email
                        email_verified_at
                        fk_user_customization
                        id_scanclick_users
                        names
                        password
                        remember_token
                        surnames
                        updated_at
                        gov_id
                        full_name
                        location {
                        lat
                        lng
                        id_city
                        city_name
                        id_country
                        country_name
                        }
                        id_provider
                    }
                }`

            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getECommerceProvider);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER los aliados del ecommerce ', error);

            });

        });

    }

    async getECommerceOrders(queryOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getECommerceOrders(queryOrders: ${JSON.stringify(queryOptions).replace(/"([^"]+)":/g, '$1:')}) {
                        id_order
                        timeline {
                          created_at
                          request_date
                          deadline_date
                          order_date
                          accept_date
                          assigment_date
                          payment_date
                          completed_date
                          cancel_date
                          refund_date
                        }
                                order {
                                
                                id_order
                                                    customer { 
                                                        avatar
                                                        company
                                                        created_at
                                                        email
                                                        email_verified_at
                                                        fk_user_customization
                                                        id_scanclick_users
                                                        names
                                                        password
                                                        remember_token
                                                        surnames
                                                        updated_at
                                                        gov_id
                                                        type
                                                        full_name
                                                        location { 
                                                            id_city
                                                            city_name
                                                            id_country
                                                            country_name
                                                            lat
                                                            lng
                                                            name
                                                            description
                                                            postal_address
                                                        }
                                                        id_customer
                                                    }
                                                    partners { 
                                                        id_provider
                                                        lat
                                                        lng
                                                        name
                                                        description
                                                        postal_address
                                                    }
                                                    type
                                                    carrierServiceType
                                                    description
                                                    comments
                                                    paymentMethod
                                                    timezone
                                                    logistic {
                                                        collect {
                                                            start_date
                                                            transit_date
                                                            delivery_date
                                                            devolution_date
                                                            items {
                                                                id_service
                                                                id_service_item
                                                                name
                                                                ean
                                                                refId
                                                                image
                                                                type
                                                                price
                                                                weight
                                                                quantity
                                                                code
                                                            }
                                                            total_qty
                                                            total_weight
                                                            partners {
                                                                id_provider
                                                                lat
                                                                lng
                                                                name
                                                                description
                                                                postal_address
                                                            } 
                                                        }
                                                        shipping {
                                                            start_date
                                                            transit_date
                                                            delivery_date
                                                            devolution_date
                                                            items {
                                                                id_service
                                                                name
                                                                ean
                                                                refId
                                                                image
                                                                type
                                                                price
                                                                weight
                                                                quantity
                                                                code
                                                            }
                                                            total_qty
                                                            total_weight
                                                        }
                                                    }
                                                    timeline {
                                                        created_at
                                                        request_date
                                                        deadline_date
                                                        order_date
                                                        accept_date
                                                        assigment_date
                                                        payment_date
                                                        completed_date
                                                        cancel_date
                                                        refund_date
                                                    }
                                                    billing {
                                                        id_bill
                                                    }
                                }
                                
                                
                                
                              }
                                        }`

            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getECommerceOrders);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER LAS ORDENES del ecommerce ', error);

            });

        });

    }

    async locateServicesProviders(order) {


        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                        locateOrderProviders(queryOrder: ${JSON.stringify(order.logistic.collect).replace(/"([^"]+)":/g, '$1:')}) {
                          logistic_collect {
                              packages
                              start_date
                              transit_date
                              delivery_date
                              devolution_date
                              items {
                                id_service
                                id_service_item
                                name
                                ean
                                refId
                                image
                                type
                                price
                                weight
                                quantity
                                code
                      
                              }
                              total_qty
                              total_weight
                              partners {
                                id_provider
                                lat
                                lng
                                name
                              }
                          },
                          
                                      
                                                  
                                  
                                        }
                                  }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.locateOrderProviders);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER localizacion servicios ', error);

            });

        });


    }

    async getOrdersBilling(queryBill) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                getOrdersBilling(queryBill: ${JSON.stringify(queryBill).replace(/"([^"]+)":/g, '$1:')}) {

                    id_bill
                    bill {
                        items {
                                img
                                name
                                items {
                                    id_service
                                    id_service_item
                                    name
                                    ean
                                    refId
                                    image
                                    type
                                    price
                                    weight
                                    quantity
                                    code
                                    }
                                partners {
                                        lat
                                        lng
                                        name
                                        plan {
                                            time
                                            distance
                                            planning {
                                                query_date
                                                collect_date
                                                process_date
                                                receive_date
                                                shipping_date
                                                postal_address
                                                prepareCollect_date
                                                prepareShipping_date
                                            }
                                        }
                                        id_provider
                                    }
                                id_service
                            }
                        id_bill
                        payment {
                            cus
                            ref
                            date
                            amount
                            receiver
                            payment_method
                            financial_entity
                        }
                        customer {
                            type
                            email
                            names
                            avatar
                            gov_id
                            company
                            location {
                                lat
                                lng
                                name
                                id_city
                                city_name
                                id_country
                                description

                                country_name
                                postal_address
                            }
                            password
                            surnames
                            full_name
                            created_at
                            updated_at
                            id_customer
                            remember_token
                            email_verified_at
                            id_scanclick_users
                            fk_user_customization
                        }
                        accounting {
                            taxes
                            posted
                            completed
                            invoice_id
                            invoice_number
                            afiliate_amount
                            ecommerce_amount
                            afiliate_amount_paid
                            ecommerce_amount_paid
                        }
                    }
                    id_order
                    timeline {
                        created_at
                        request_date
                        deadline_date
                        order_date
                        accept_date
                        assigment_date
                        payment_date
                        completed_date
                        cancel_date
                        refund_date

                    }
                    

                  }
                }`
            }).then((orderBilling) => {

                resolve(orderBilling.data.data.getOrdersBilling);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER LAS FACTURAS DE LAS ORDENES ', error);


            });

        });

    }

    async getCategoryServices(id_category) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                getCategoryServices(id_category: ${id_category}) {
                
                            id_service
                            id_category
                            created_at
                            is_active
                            is_available
                            code
                            name
                            description
                            img
                            title
                            avatar
                            userFullName
                            blogPosted
                            configuration {
                                name
                                type
                                promocionals {
                                    id_promocional
                                    created_at
                                    is_active
                                    is_available
                                    code
                                    name
                                    description
                                    img
                                    title
                                    avatar
                                    userFullName
                                    blogPosted
                                    excerpt
                                    comment

                                }
                            }
            
                  }
            }`
            }).then((categoriesServicesList) => {

                resolve(categoriesServicesList.data.data.getCategoryServices);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER servicios ', error);


            });

        });

    }

    async updateCategory(editableCategory) {

        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    updateCategory(editableCategory: ${JSON.stringify(editableCategory).replace(/"([^"]+)":/g, '$1:')})  {
                       id_category
                       created_at
                       is_active
                       is_available
                       code
                       name
                       description
                       img
                       title
                       avatar
                       userFullName
                       blogPosted
                    }
   
                   }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.updateCategory);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });
    }

    async createService(newService) {

        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    createService(newService: ${JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:')})  {
                       id_category
                       id_service
                       created_at
                       is_active
                       is_available
                       code
                       name
                       description
                       img
                       title
                       avatar
                       userFullName
                       blogPosted
   
                    }
   
                   }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.createService);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async updateService(newService) {

        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    updateService(editableService: ${JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:')})  {
                       id_category
                       id_service
                       created_at
                       is_active
                       is_available
                       code
                       name
                       description
                       img
                       title
                       avatar
                       userFullName
                       blogPosted
   
                    }
   
                   }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.updateService);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async createServiceItem(newServiceItem) {

        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    createServiceItem(newServiceItem: ${JSON.stringify(newServiceItem).replace(/"([^"]+)":/g, '$1:')})  {
                        id_service
                        id_service_item
                        name
                        ean
                        refId
                        image
                        type
                        price
                        weight
                        quantity
                        code
   
                    }
   
                   }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.createServiceItem);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async updateServiceItem(newServiceItem) {

        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                    updateServiceItem(newServiceItem: ${JSON.stringify(newServiceItem).replace(/"([^"]+)":/g, '$1:')})  {
                        id_service
                        id_service_item
                        name
                        ean
                        refId
                        image
                        type
                        price
                        weight
                        quantity
                        code
   
                    }
   
                   }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.updateServiceItem);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async createCategory(newCategory) {

        delete newCategory.id_category;
        // delete newCategory.services;
        //delete newCategory.tags;
        // newCategory.img = 'hola';
        // newCategory.avatar = 'si';

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                 createCategory(newCategory: ${JSON.stringify(newCategory).replace(/"([^"]+)":/g, '$1:')})  {
                    id_category
                    created_at
                    is_active
                    is_available
                    code
                    name
                    description
                    img
                    title
                    avatar
                    userFullName
                    blogPosted

                 }

                }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.createCategory);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });

        });

    }

    async createOrder(newOrder) {

        newOrder.logistic.dispatch.routes.fromCustomer = btoa(newOrder.logistic.dispatch.routes.fromCustomer.replace('\\', ''));
        newOrder.logistic.dispatch.routes.fromProvider = btoa(newOrder.logistic.dispatch.routes.fromProvider.replace('\\', ''));
        console.log('sisa', newOrder.logistic.dispatch.routes.fromCustomer);

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `mutation {
                 createOrder(newOrder: ${JSON.stringify(newOrder).replace(/"([^"]+)":/g, '$1:')})  {
                    createdOrder {
                        id_order
                        customer { 
                            avatar
                            company
                            created_at
                            email
                            email_verified_at
                            fk_user_customization
                            id_scanclick_users
                            names
                            password
                            remember_token
                            surnames
                            updated_at
                            gov_id
                            type
                            full_name
                            location { 
                                id_city
                                city_name
                                id_country
                                country_name
                                lat
                                lng
                                name
                                description
                                postal_address
                            }
                            id_customer
                        }
                        partners { 
                            id_provider
                            lat
                            lng
                            name
                            description
                            postal_address
                        }
                        type
                        carrierServiceType
                        description
                        comments
                        paymentMethod
                        timezone
                        logistic {
                            collect {
                                start_date
                                transit_date
                                delivery_date
                                devolution_date
                                items {
                                    id_service
                                    id_service_item
                                    name
                                    ean
                                    refId
                                    image
                                    type
                                    price
                                    weight
                                    quantity
                                    code
                                }
                                total_qty
                                total_weight
                                partners {
                                    id_provider
                                    lat
                                    lng
                                    name
                                    description
                                    postal_address
                                } 
                            }
                            shipping {
                                start_date
                                transit_date
                                delivery_date
                                devolution_date
                                items {
                                    id_service
                                    name
                                    ean
                                    refId
                                    image
                                    type
                                    price
                                    weight
                                    quantity
                                    code
                                }
                                total_qty
                                total_weight
                            }
                        }
                        timeline {
                            created_at
                            request_date
                            deadline_date
                            order_date
                            accept_date
                            assigment_date
                            payment_date
                            completed_date
                            cancel_date
                            refund_date
                        }
                        billing {
                            id_bill
                        }
                    }

                 }

                }`
            }).then((categoryCreated) => {

                resolve(categoryCreated.data.data.createOrder);
                // console.log('resultado ', categoryCreated.data.data.createCategory);

            });
            // resolve(true);

        });

    }

    async getPromocionals(filterOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                        getPromocionals(promocionalsQueryInput: ${JSON.stringify(filterOptions).replace(/"([^"]+)":/g, '$1:')})  {
                           id_promocional
                    created_at
                    is_active
                    is_available
                    code
                    name
                    description
                    img
                    title
                    avatar
                    userFullName
                    blogPosted
                    tags {
                      id_category
                      id_category_tags
                      tags
                    },
                    excerpt
                    comment
                    services {
                        id_service
                        label
                    }
                        }
       
                       }`
            }).then((categoriesList) => {

                resolve(categoriesList.data.data.getPromocionals);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER EL CATALOG ', error);


            });

        });

    }

    async getCategories(filterOptions) {

        return new Promise((resolve, reject) => {

            axiosECommerce.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceCatalogMicroService', {
                query: `query {
                    getCategories(categoriesQueryInput: ${JSON.stringify(filterOptions).replace(/"([^"]+)":/g, '$1:')})  {
                    
                                id_category
                                created_at
                                is_active
                                is_available
                                code
                                name
                                description
                                img
                                title
                                avatar
                                userFullName
                                blogPosted
                                tags {
                                  
                                  id_category_tags
                                    id_category
                                    tags
                      
                                }
                                services {
                                    id_service
                                    id_category
                                    created_at
                                    is_active
                                    is_available
                                    code
                                    name
                                    description
                                    img
                                    title
                                    avatar
                                    userFullName
                                    blogPosted
                                    catalog {

                                        id_service
                        id_service_item
                        name
                        ean
                        refId
                        image
                        type
                        price
                        weight
                        quantity
                        code

                                    }
                                }
                                excerpt
                                comment
                
                      }
                    }`
            }).then((categoriesList) => {

                resolve(categoriesList.data.data.getCategories);


            }).catch((error) => {

                reject([]);
                console.log('HA OCURRIDO UN ERROR AL OBTENER EL CATALOG ', error);


            });

        });


        // let categoriesList = [new Category(), new Category(), new Category()];
        // categoriesList[0].name = 'Laundry';

        // categoriesList[0].services = [new Service(), new Service()];
        // categoriesList[0].services[0].name = 'Laundry x Prendas';
        // categoriesList[0].services[0].attributes = [new Attribute()];
        // categoriesList[0].services[0].attributes[0].name = 'Alto';
        // categoriesList[0].services[0].attributes[0].value = 300;
        // categoriesList[0].services[0].attributes[0].type = 'N'

        // categoriesList[0].services[1].name = 'Laundry x Peso';


        // categoriesList[1].name = 'Limpieza de Fachadas';
        // categoriesList[1].services = [];
        // categoriesList[2].name = 'Mascotas';
        // categoriesList[2].services = [];
        // console.log('scream ', categoriesList);

    }

    getServices() {

    }
}